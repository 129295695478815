import { Component, OnInit, OnDestroy } from '@angular/core';
import { InputConverterService, IInspectionSearch } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
	selector: 'app-inspection-search',
	templateUrl: './inspection-search.component.html',
	styleUrls: ['./inspection-search.component.scss']
})
export class InspectionSearchComponent implements OnInit, OnDestroy {
	private _routeParamsSub: Subscription;
	private _routeQueryParamsSub: Subscription;

	public keyword: string;
	public vesselID: string;
	public numDays: number;
	public page: number;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router) {
		}

	ngOnInit() {
		this._routeParamsSub = this._route.paramMap.subscribe(paramMap => {
			this.parseRouteParams(paramMap);
		});

		this._routeQueryParamsSub = this._route.queryParamMap.subscribe(queryParamMap => {
			this.parseRouteQueryParams(queryParamMap);
		});
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this._routeQueryParamsSub.unsubscribe();
	}

	private parseRouteParams(paramMap: ParamMap): void {
		if (paramMap == null) {
			return;
		}

		this.numDays = InputConverterService.parseInt(paramMap.get('numDays'));
	}

	private parseRouteQueryParams(queryParamMap: ParamMap): void {
		let keyword = '';
		if (queryParamMap.has('keyword')) {
			keyword = queryParamMap.get('keyword');
		}

		let vesselId = '';
		if (queryParamMap.has('vesselID')) {
			vesselId = queryParamMap.get('vesselID');
		}

		let page: number = null;
		if (queryParamMap.has('page')) {
			page = InputConverterService.parseInt(queryParamMap.get('page'));
		}

		if (page == null) {
			page = 1;
		}

		this.keyword = keyword;
		this.vesselID = vesselId;
		this.page = page;
	}

	public onSearchChanged(searchParams: IInspectionSearch) {
		const queryParams: { page: number, keyword?: string, vesselID?: string} = {
			page: this.page
		};

		if (!InputConverterService.isEmpty(searchParams.Keyword)) {
			queryParams.keyword = searchParams.Keyword;
		}
		if (!InputConverterService.isEmpty(searchParams.VesselID)) {
			queryParams.vesselID = searchParams.VesselID;
		}

		this._router.navigate(['inspections/search', searchParams.NumDays], { queryParams });
	}

	public onPageChanged(page: number): void {
		this._router.navigate([], {
			queryParams: {
				page
			},
			queryParamsHandling: 'merge'
		});
	}
}
