import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { FIELD_TECH_APP_CONFIG } from './app.config';
import { FIELD_TECH_AUTH_CONFIG } from './auth.config';

import { APP_CONFIG, AUTH_CONFIG, EeCommonLibModule, JwtInterceptor, ErrorInterceptor } from 'ee-common-lib';
import { FieldTechPortalComponent } from './field-tech-portal/field-tech-portal.component';
import { CreateInspectionComponent } from './create-inspection/create-inspection.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RecentInspectionsComponent } from './recent-inspections/recent-inspections.component';
import { ScheduledInspectionsComponent } from './scheduled-inspections/scheduled-inspections.component';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RoutesNavComponent } from './routes-nav/routes-nav.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { RoutesComponent } from './routes/routes.component';
import { RouteTableComponent } from './route-table/route-table.component';
import { DeleteRouteButtonComponent } from './delete-route-button/delete-route-button.component';

@NgModule({
	declarations: [
		AppComponent,
		FieldTechPortalComponent,
		CreateInspectionComponent,
		NavbarComponent,
		UserProfileComponent,
		RecentInspectionsComponent,
		ScheduledInspectionsComponent,
		InspectionDetailsComponent,
		InspectionSearchComponent,
		SidebarComponent,
		RoutesNavComponent,
		WellOverviewComponent,
		RoutesComponent,
		RouteTableComponent,
		DeleteRouteButtonComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		EeCommonLibModule,
		HttpClientModule,
		ReactiveFormsModule
	],
	providers: [
		{
			provide: APP_CONFIG,
			useValue: FIELD_TECH_APP_CONFIG
		},
		{
			provide: AUTH_CONFIG,
			useValue: FIELD_TECH_AUTH_CONFIG
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
