import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomer, IInspectionConstants, IEditInspection, InspectionService, IError,
	IEditInspectionData, EditInspectionFormComponent, IEditInspectionFormResult  } from 'ee-common-lib';

interface ICreateInspectionRouteData {
	editInspection: IEditInspectionData;
}

@Component({
	selector: 'app-create-inspection',
	templateUrl: './create-inspection.component.html',
	styleUrls: ['./create-inspection.component.scss']
})
export class CreateInspectionComponent implements OnInit, OnDestroy {
	@ViewChild(EditInspectionFormComponent, { static: false })
	private _inspectionForm: EditInspectionFormComponent;

	public inspection: IEditInspection;
	public customers: ICustomer[];
	public constants: IInspectionConstants;
	public creatingInspection: boolean;
	public errorMsg: string;
	public successMsg: string;

	private _routeDataSub: Subscription;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _inspectionService: InspectionService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe((routeData: ICreateInspectionRouteData) => {
			this.inspection = routeData.editInspection.Inspection;
			this.customers = routeData.editInspection.Customers;
			this.constants = routeData.editInspection.InspectionConstants;
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onSave(result: IEditInspectionFormResult) {
		this.successMsg = '';
		this.errorMsg = '';
		this.creatingInspection = true;

		this._inspectionService.createInspection(result.Inspection, result.FieldNoteAssetFile, result.InspectionReportFile).subscribe({
			next: () => {
				this.successMsg = 'Inspection created successfully. Redirecting...';
				this.creatingInspection = false;
				this._inspectionForm.reset();

				setTimeout(() => {
					this._router.navigate(['/']);
				}, 3000);
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.creatingInspection = false;
			}
		});
	}
}
