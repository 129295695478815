import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IInspectionListItem, InspectionService, InputConverterService, APP_CONFIG, IAppConfig, IError, IInspection } from 'ee-common-lib';

@Component({
	selector: 'app-recent-inspections',
	templateUrl: './recent-inspections.component.html',
	styleUrls: ['./recent-inspections.component.scss']
})
export class RecentInspectionsComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public inspections: IInspectionListItem[];
	public page: number;
	public pageCount: number;
	public totalInspectionCount: number;
	public pageSize: number;

	private _queryParamsSub: Subscription;
	private _inspectionsSub: Subscription;

	constructor(
		private _inspectionService: InspectionService,
		private _route: ActivatedRoute,
		private _router: Router,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
			this.pageSize = this._appConfig.pageSize;
		}

	ngOnInit() {
		this._queryParamsSub = this._route.queryParamMap.subscribe(queryParams => {
			let page = 1;
			if (queryParams.has('page')) {
				page = InputConverterService.parseInt(queryParams.get('page'));
			}

			if (this.page !== page) {
				this.page = page;
				this.refreshInspections();
			}
		});
	}

	ngOnDestroy() {
		this._queryParamsSub.unsubscribe();

		this.unsubscribeInspections();
	}

	public onPageChanged(page: number): void {
		this.page = page;
		this._router.navigate([], {
			relativeTo: this._route,
			queryParams: { page},
			queryParamsHandling: 'merge'
		});

		this.refreshInspections();
	}

	private refreshInspections(): void {
		this.unsubscribeInspections();

		this.loading = true;
		this._inspectionsSub = this._inspectionService.getRecentInspectionsList(this.page, this.pageSize).subscribe({
			next: inspectionPage => {
				this.inspections = inspectionPage.Items;
				this.pageCount = inspectionPage.PageCount;
				this.pageSize = inspectionPage.PageSize;
				this.totalInspectionCount = inspectionPage.TotalItemCount;
				this.loading = false;
			},
			error: (error: IError) => {
				this.loading = false;
			}
		});
	}

	private unsubscribeInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	public onEditInspection(inspectionID: number) {
		this._router.navigate(['inspection', inspectionID, 'edit']);
	}

	public onPreviousInspectionRequested(previousInspection: IInspection) {
		this._router.navigate(['inspection', previousInspection.InspectionID, 'details']);
	}
}
