import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, ChangePasswordComponent, InspectionResolverService,
	CreateWellComponent, CustomersResolverService, StatesResolverService, WellResolverService,
	EditInspectionResolverService, UpdateInspectionComponent, DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE} from 'ee-common-lib';
import { FieldTechPortalComponent } from './field-tech-portal/field-tech-portal.component';
import { ScheduledInspectionsComponent } from './scheduled-inspections/scheduled-inspections.component';
import { CreateInspectionComponent } from './create-inspection/create-inspection.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RecentInspectionsComponent } from './recent-inspections/recent-inspections.component';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';
import { RoutesComponent } from './routes/routes.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { WellInspectionsPageResolverService } from './well-inspections-page-resolver.service';

const routes: Routes = [
	{
		path: 'login',
		component: FieldTechPortalComponent,
	},
	{
		path: '',
		redirectTo: 'inspections/scheduled',
		pathMatch: 'full'
	},
	{
		path: 'inspections/scheduled',
		canActivate: [AuthGuard],
		component: ScheduledInspectionsComponent
	},
	{
		path: 'inspections/recent',
		component: RecentInspectionsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspections/search',
		redirectTo: `inspections/search/${DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE}`,
		pathMatch: 'full'
	},
	{
		path: 'inspections/search/:numDays',
		component: InspectionSearchComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'routes',
		redirectTo: 'routes/',
		pathMatch: 'full',
		canActivate: [AuthGuard]
	},
	{
		path: 'routes/:routeID/wells/:wellID',
		component: WellOverviewComponent,
		canActivate: [AuthGuard],
		resolve: {
			well: WellResolverService,
			page: WellInspectionsPageResolverService
		}
	},
	{
		path: 'routes/:customerID',
		component: RoutesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspection/new',
		component: CreateInspectionComponent,
		canActivate: [AuthGuard],
		resolve: {
			editInspection: EditInspectionResolverService
		}
	},
	{
		path: 'inspection/:inspectionID/edit',
		component: UpdateInspectionComponent,
		canActivate: [AuthGuard],
		resolve: {
			editInspection: EditInspectionResolverService
		}
	},
	{
		path: 'inspection/:inspectionID/details',
		component: InspectionDetailsComponent,
		canActivate: [AuthGuard],
		resolve: {
			inspection: InspectionResolverService
		}
	},
	{
		path: 'well/add',
		component: CreateWellComponent,
		canActivate: [AuthGuard],
		resolve: {
			producers: CustomersResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'profile',
		component: UserProfileComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile/changePassword',
		component: ChangePasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspections/scheduled/:year/:month',
		canActivate: [AuthGuard],
		component: ScheduledInspectionsComponent
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
