import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	InputConverterService, IKeywordSearchFormResult, MONTH_YEAR_DATE_FORMAT, IInspectionListItem,
	IInspection, IInspectionMonth, IError, ICalendarMonth, InspectionService, ICustomer, CustomerService, IState} from 'ee-common-lib';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

interface IRouteData {
	producers: ICustomer[];
}
interface IStateData {
	states: IState[];
}
@Component({
	selector: 'app-scheduled-inspections',
	templateUrl: './scheduled-inspections.component.html',
	styleUrls: ['./scheduled-inspections.component.scss']
})
export class ScheduledInspectionsComponent implements OnInit, OnDestroy {
	private _routeQueryParamsSub: Subscription;
	private _inspectionsSub: Subscription;
	private _routeParamsSub: Subscription;
	public loading;
	public currentMonth: ICalendarMonth;
	public keyword: string;
	public remainingInspections: string;
	public inspectionsByMonth: IInspectionMonth[];
	public inspections: IInspectionListItem[];
	public inspectionDateFormat: string;
	public producers: ICustomer[];
	public states: IState[];
	public errorMsg: string;
	public selectedCustomer: number;
	constructor(private _router: Router, private _route: ActivatedRoute, private _customerService: CustomerService, private _inspectionService: InspectionService) {
		this.inspectionDateFormat = MONTH_YEAR_DATE_FORMAT;
	}

	ngOnInit() {
		//this._customerService.getCustomersForFilters().subscribe({
		//	next: producers => {
		//		this.producers = producers;
		//	},
		//	error: (error: IError) => {
		//		this.errorMsg = error.Message;
		//	}
		//});
		//const snapshot = this._route.snapshot;
		//this.parseRouteQueryParams(snapshot.queryParamMap);
		//this.refreshInspections();

		//this._routeQueryParamsSub = this._route.queryParamMap.subscribe(queryParamMap => {
		//	if (this.parseRouteQueryParams(queryParamMap)) {
		//		this.refreshInspections();
		//	}
		//});

		this.selectedCustomer = -1;
		this._customerService.getCustomersForFilters().subscribe({
			next: producers => {
				this.producers = producers;


			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});
		this._customerService.getStates().subscribe({
			next: states => {
				this.states = states;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});

		this._routeParamsSub = this._route.paramMap.subscribe(params => {
			if (params.has('month') && params.has('year')) {
				const year = InputConverterService.parseInt(params.get('year'));
				const month = InputConverterService.parseInt(params.get('month'));

				this.currentMonth = {
					month,
					year
				};
				this.refreshInspections();
			} else {
				this.onCurrentMonthChanged({
					month: InputConverterService.getCurrentMonth(),
					year: InputConverterService.getCurrentYear()
				});

				const snapshot = this._route.snapshot;
				this.parseRouteQueryParams(snapshot.paramMap);
				this.refreshInspections();
			}
		});
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this.cancelRefreshInspections();
	}

	private parseRouteQueryParams(queryParamMap: ParamMap) {
		let keyword: string = null;
		if (queryParamMap.has('keyword')) {
			keyword = queryParamMap.get('keyword');
		}

		const hasChanged = this.keyword !== keyword;

		this.keyword = keyword;

		return hasChanged;
	}

	public onCurrentMonthChanged(newMonth: ICalendarMonth) {
		this.currentMonth = {
			year: newMonth.year,
			month: newMonth.month
		};

		this._router.navigate(['inspections', 'scheduled', newMonth.year, newMonth.month]);
		this.refreshInspections(null, this.selectedCustomer);
	}
	private cancelRefreshInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	public refreshInspections(searchWord: string = null, customer_id: number = -1, state_id: string = "-1") {
		this.cancelRefreshInspections();

		this.loading = true;
		//this._inspectionsSub = this._inspectionService.getYearlyScheduledInspectionsList(this.keyword, customer_id).subscribe({
		//	next: inspectionsByMonth => {
		//		this.inspectionsByMonth = inspectionsByMonth;
		//		this.loading = false;
		//	},
		//	error: (error: IError) => {
		//		this.loading = false;
		//	}
		//});
		this.inspections = null;
		this._inspectionsSub = this._inspectionService.getScheduledInspectionsListByMonth(
			this.currentMonth.year, this.currentMonth.month, searchWord, this.selectedCustomer, state_id).subscribe({
				next: (inspections: IInspectionListItem[]) => {
					this.inspections = inspections;
					this.loading = false;
					this.remainingInspections = inspections.length.toString();
				},
				error: (error: IError) => {
					this.loading = false;
				}
			});
	}

	public onEditInspection(inspectionID: number) {
		//this._router.navigate(['inspection', inspectionID, 'edit']);
		window.open('inspection/' + inspectionID + '/edit', '_blank');
	}

	public onKeywordChanged(event: IKeywordSearchFormResult) {
		let queryParams = null;
		if (InputConverterService.isEmpty(event.SearchTerm)) {
			queryParams = {};
		} else {
			queryParams = {
				keyword: event.SearchTerm,
				customer_ID: event.CustomerID,
				state: event.StateID
			};
		}

		this._router.navigate([], {
			relativeTo: this._route,
			queryParams
		});
		this.selectedCustomer = event.CustomerID;
		this.refreshInspections(queryParams.keyword, event.CustomerID, event.StateID);
	}

	public onPreviousInspectionRequested(previousInspection: IInspection) {
		//this._router.navigate(['inspection', previousInspection.InspectionID, 'details']);
		window.open('inspection/' + previousInspection.InspectionID +  '/details', '_blank');
	}
}
