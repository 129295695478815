import { Component, Inject } from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG, IAppConfig } from 'ee-common-lib';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	flag: boolean;
	constructor(
		private _paginationService: NgbPaginationConfig,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
		this._paginationService.maxSize = this._appConfig.maxNumPages;

	}
	addItem(newItem: boolean) {
		this.flag = newItem;
	}
}
