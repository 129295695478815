import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-field-tech-portal',
	templateUrl: './field-tech-portal.component.html',
	styleUrls: ['./field-tech-portal.component.scss']
})
export class FieldTechPortalComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
